import { useAuthStore } from "~~/stores/auth";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth = useAuthStore();
  if ((auth.token !== undefined) && (auth.token !== '') &&  (auth.token !== null))  {
    const authenticated = await auth.checkToken();
    if (authenticated) {
      navigateTo(to.fullPath)
    } else {
      return abortNavigation('Token inválido!')
      //return navigateTo('/login')
    }
  }else {
     return navigateTo('/login')
  }

})